<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-10-08 15:24:49
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-08 16:50:40
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/CapitalManager/RechargeModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="addSourceVisible"
    @onCancel="handleCancel"
    @ok="handleCancel"
    :closable="false"
    width="356px"
  >
    <!-- <div class="title" slot="title">123123</div> -->
    <img src="./head-img.png" alt="" slot="title" class="head">
    <h4>计费标准</h4>
    <p><strong>总费用</strong>:总费用涵盖了平台使用的全部成本，主要包括两部分：一是使用平台开放流量的费用，二是平台基础请求填充的平台服务费。</p>
    <p><strong>1.MergeX开放流量费用</strong>:购买MergeX开放流量的费用，即在MergeX开放供应商中媒体出价的费用。这一费用在数据报表中显示为“媒体消耗”字段。（未使用不产生费用）</p>
    <p><strong>2.平台服务费</strong>:平台根据广告请求和流量填充的次数收取使用费。具体标准为：每一百万次广告请求收费1元，每一百万次流量填充收费30元。</p>
    <div class="footer" slot="footer">
      <a-button class="btn" type="primary" @click="handleCancel">确定</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.addSourceVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.head{
  width: 100%;
  border-radius: @mediumRadius @mediumRadius 0 0;
}
p, h4{
  font-size: 14px;
  line-height: 25.2px;
  color: @defaultFontColor;
}
h4{
  font-size: 16px;
}
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .btn{
    width: 100%!important;
  }
}
::v-deep{
  .ant-modal-header{
    padding: 0;
  }
  .ant-modal-wrap .ant-modal .ant-modal-footer{
    border-top: none;
    padding-top: 0;
  }
  .ant-modal-body{
    border-radius: @mediumRadius @mediumRadius 0 0;
    background: #fff;
    transform: translateY(-10px);
    padding-bottom: 0;
  }
}
</style>
